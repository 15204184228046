import React, { useEffect, useMemo, useState } from 'react'
import { PlayIcon } from '@heroicons/react/outline'
import { Button, Modal } from '@mantine/core'
import { QueryBuilderDnD } from '@react-querybuilder/dnd'
import { atom, useAtom, useSetAtom } from 'jotai'
import { XIcon } from 'lucide-react'
import * as ReactDnD from 'react-dnd'
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend'
import {
  defaultOperators,
  defaultValidator,
  Field,
  formatQuery,
  QueryBuilder,
  RuleGroupType,
  RuleValidator,
  ValidationResult,
} from 'react-querybuilder'
import { getUntrackedObject } from 'react-tracked'
import { SearchMd } from 'untitled-icons'

import { VideoModal } from '@/components/VideoModal'
import { QueryBuilderMantine } from '@/lib/react-querybuilder-mantine'
import { settingsMenuAtom } from '../../_components/SettingsMenu'
import { useTrackedSettings } from '../../_hooks/useGlobalSettings'
import { useTrackedMIStore } from '../_hook'

const validator: RuleValidator = (q): ValidationResult => {
  return {
    valid: !!q.value || q.value.length > 0,
    reasons: ['this field cannot be empty'],
  }
}

export const advancedSearchFields: Field[] = [
  {
    name: 'q_title',
    label: 'Title',
  },
  {
    name: 'q_desc',
    label: 'Description',
  },
  {
    name: 'q_ing',
    label: 'Ingredients',
  },
].map((field) => ({
  ...field,
  operators: defaultOperators.filter((op) => ['contains', 'doesNotContain'].includes(op.name)),
  validator,
}))

export const advancedModeIndicator = atom(false)

export const AdvancedSearch = () => {
  const [opened, setOpened] = useState(false)
  const { advancedSearch, setAdvancedSearch } = useTrackedMIStore()
  const [query, setQuery] = useState<RuleGroupType>({
    combinator: 'or',
    rules: [
      { field: 'q_title', operator: 'contains', value: '' },
      { field: 'q_desc', operator: 'contains', value: '' },
      { field: 'q_ing', operator: 'contains', value: '' },
    ],
  })

  const [showIndicator, setShowIndicator] = useAtom(advancedModeIndicator)

  useEffect(() => {
    if (showIndicator) {
      setTimeout(() => {
        setShowIndicator(false)
      }, 5000)
    }
  }, [setShowIndicator, showIndicator])

  useEffect(() => {
    const ob = getUntrackedObject(advancedSearch)
    if (ob) {
      setQuery(ob)
    }
  }, [])

  const expression = useMemo(
    () =>
      formatQuery(query, {
        format: 'sql',
        fields: advancedSearchFields,
      }),
    [query]
  )

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false)
        }}
        size={1000}
        centered
        withCloseButton={false}
        className="relative"
        // p={0}
      >
        <button className="absolute right-8 top-8" onClick={() => setOpened(false)}>
          <XIcon className="size-5 text-primary-500" />{' '}
        </button>
        <div className="space-y-6 p-3 text-left">
          <div className="space-y-2">
            <h1 className="text-lg font-semibold">Advanced Search</h1>
            <VideoModal
              href="https://eyva-reports.s3.eu-central-1.amazonaws.com/Clickable+Attributes.mp4"
              className="flex items-center space-x-1.5 text-sm text-accent-600 hover:underline"
            >
              <PlayIcon className="size-5" />{' '}
              <p>Click here to watch video tutorial to help you understand this feature.</p>
            </VideoModal>
          </div>
          <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
            <QueryBuilderMantine>
              <QueryBuilder
                fields={advancedSearchFields}
                query={query}
                onQueryChange={setQuery}
                controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
                validator={defaultValidator}
              />
            </QueryBuilderMantine>
          </QueryBuilderDnD>
          <Button
            color="gray"
            disabled={query.rules.length === 0 || expression === '()'}
            onClick={() => {
              setAdvancedSearch(query)
              setOpened(false)
            }}
          >
            Search
          </Button>
        </div>
      </Modal>
      <Button
        variant="outline"
        color="gray"
        fullWidth
        onClick={() => {
          setOpened(true)
          setShowIndicator(false)
        }}
        className="relative rounded-md !border-gray-200 !bg-gray-50 p-2 hover:!bg-gray-100"
      >
        <div className="relative mx-4 flex items-center">
          {showIndicator && <div className="pulsating-dot absolute -right-1 -top-1"></div>}
          <SearchMd className="mr-1 size-4" />
          <p> Advanced Search</p>
        </div>
      </Button>
    </>
  )
}

export const ActivateAdvancedSearch = () => {
  const { setAdvancedMode } = useTrackedSettings()
  const setShowIndicator = useSetAtom(advancedModeIndicator)
  const setShowSettingsMenu = useSetAtom(settingsMenuAtom)
  return (
    <div className="text-xs text-gray-700">
      For a more tailored search experience, activate{' '}
      <button
        className="inline-flex items-center space-x-0.5 border-b-2 border-gray-700 font-semibold hover:border-accent-600 hover:text-accent-600"
        style={{ verticalAlign: 'top' }}
        onClick={() => {
          setShowIndicator(true)
          setAdvancedMode(true)
          setShowSettingsMenu(true)
        }}
      >
        <SearchMd className="size-[13px] [&_path]:stroke-[3]" /> <p>Advanced Search</p>
      </button>{' '}
      to refine your results and find exactly what you need.
    </div>
  )
}
